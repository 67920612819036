import { CustomMetric } from "interfaces/db"
import { ATOB_TEAM_ID, ATTYX_TEAM_ID, CAPITAL_GENERATIONS_TEAM_ID, CLARION_TEAM_ID, ELEVATEK12_TEAM_ID, FLOATCARD_TEAM_ID, FOX_ORDERING_TEAM_ID, GAINSIGHT_TEAM_ID, GOODKIND_TEAM_ID, GRAINCLEANER_TEAM_ID, MAGIC_TEAM_ID, MONEX_TEAM_ID, NINJAONE_TEAM_ID, PIGMENT_TEAM_ID, PROPEL_TEAM_ID, SEESAW_TEAM_ID, TRELLUS_TEAM_ID, TRENDHUNTER_TEAM_ID, VENACORP_TEAM_ID } from "./const"
import { Diagnostic } from "interfaces/services"

export enum OVERALL_SCORE_TYPE {
    PERCENTAGE = 'PERCENTAGE',
    RAW = 'RAW'
}

export type MetricFormat = {
    score_is_applicable: boolean
    overall_score: number | string | null
    overall_explanation: string | null
    overall_score_type: OVERALL_SCORE_TYPE,
    overall_score_name?: string,
    metrics: MetricDefinition[]
}

export enum MetricType {
    SUCCESS = 'SUCCESS',
    SCORE = 'SCORE',
    QUALITATIVE = 'QUALITATIVE'
}

export type MetricDefinition = {
    metric_type: MetricType
    metric_name: string
    value: number | boolean | string | null
    max_value?: number
    text?: string
}

export type CustomMetricDefinition = MetricDefinition & {
    options?: string[]
    optionsToValueMap?: {[k: string]: number}
    selected_options?: string[]
    minRange?: number
    maxRange?: number
    selected?: boolean
    field_name: string
    version: string
  }


export function AttyxMetricFormat(customMetric: CustomMetric): MetricFormat  {
    const serviceType: MetricDefinition = {
        metric_type: MetricType.QUALITATIVE,
        metric_name: "Service",
        value: customMetric.metric_1 === 1 ? 'Roofing' : customMetric.metric_1 === 2 ? 'New HVAC' : customMetric.metric_1 === 3 ? 'Both' : 'Unknown',
    } 

    const channelType: MetricDefinition = {
        metric_type: MetricType.QUALITATIVE,
        metric_name: "Type",
        value: customMetric.metric_2 === 1 ? 'Inbound' : customMetric.metric_2 === 2 ? 'Followup' : "Outbound",
    }

    const zipCode: MetricDefinition = {
        metric_type: MetricType.QUALITATIVE,
        metric_name: "Stage",
        value: customMetric.metric_3 === 0 ? 'Beginning' : customMetric.metric_3 === 1 ? 'Middle' : 'End', 
    }

    const titleDiscussed: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Zip",
        value: customMetric.metric_4 === 1
    }

    const shuttoffDiscussed: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Pain - Where/When",
        value: customMetric.metric_5 === 1,
    }

    const creditScore: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Pain - Reason",
        value: customMetric.metric_6 === 1,
    }

    const workingStatus: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "IRA",
        value: customMetric.metric_7 === 1,
    }

    const hasIssues: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Replacement",
        value: customMetric.metric_8 === 1,
    }

    const billReduction: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Current Bill",
        value: customMetric.metric_9 === 1,
    }

    const inflationReduction: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "No Cost",
        value: customMetric.metric_10 === 1,
    }

    const freeDiscussed: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Credit",
        value: customMetric.metric_11 === 1,
    }

    const bookedTime: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Homeowner",
        value: customMetric.metric_12 === 1,
    }

    const employed: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Employed",
        value: customMetric.metric_13 === 1,
    }
    const address: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Address",
        value: customMetric.metric_14 === 1,
    }

    const dateTime: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Date",
        value: customMetric.metric_15 === 1,
    }

    const nextSteps: MetricDefinition = {
        metric_type: MetricType.SUCCESS,
        metric_name: "Next Steps",
        value: customMetric.metric_16 === 1,
    }

    return {
        score_is_applicable: true,
        overall_score: customMetric.metric_0 !== null ? Math.round(100*(customMetric.metric_0 ?? 0)) : null,
        overall_score_type: OVERALL_SCORE_TYPE.PERCENTAGE,
        metrics: [
            serviceType,
            channelType,
            zipCode,
            titleDiscussed,
            shuttoffDiscussed,
            creditScore,
            workingStatus,
            hasIssues,
            billReduction,
            inflationReduction,
            freeDiscussed,
            bookedTime,
            employed,
            address,
            dateTime,
            nextSteps
        ],
        overall_explanation: null,
    }
}

export function FoxOrderingMetricFormat(customMetric: CustomMetric): MetricFormat {
    const score_categories: {[k: number]: string} = {
        0: "No Contact",
        1: "VM Reached",
        2: "Reached prospect - no proposal/apathy",
        3: "Proposal received - no interested",
        4: "Hot opportunity",
        5: "Deal Won",
    }
    const score_text = customMetric.metric_0 !== null && customMetric.metric_0.toString() in Object.keys(score_categories) ? score_categories[customMetric.metric_0] : "Unknown"
    return {
        score_is_applicable: customMetric.metric_0 !== null,
        overall_score: score_text,
        overall_explanation: customMetric.text_0,
        overall_score_type: OVERALL_SCORE_TYPE.RAW,
        metrics: [],
        overall_score_name: "Outcome"
    }
}

export function GenAIMetricFormat(customMetric: CustomMetric): MetricFormat {
    const openerMetric: MetricDefinition = {
        metric_type: MetricType.SCORE,
        metric_name: "Opener",
        value: customMetric.metric_1,
        text: customMetric.text_1 ?? undefined,
        max_value: 2,
    }

    const objectionMetric: MetricDefinition = {
        metric_type: MetricType.SCORE,
        metric_name: "Objection",
        value: customMetric.metric_2,
        text: customMetric.text_2 ?? undefined,
        max_value: 2,
    }

    const qualificationMetric: MetricDefinition = {
        metric_type: MetricType.SCORE,
        metric_name: "Qualification",
        value: customMetric.metric_3,
        text: customMetric.text_3 ?? undefined,
        max_value: 2,
    }

    const closingMetric: MetricDefinition = {
        metric_type: MetricType.SCORE,
        metric_name: "Closing",
        value: customMetric.metric_4,
        text: customMetric.text_4 ?? undefined,
        max_value: 2,
    }

    const metrics: MetricDefinition[] = [openerMetric, objectionMetric, qualificationMetric, closingMetric]
    const overall_max_score = metrics.filter((v) => v.value !== null && v.max_value !== undefined).reduce((acc, v) => acc + (v.max_value as number), 0)

    return {
        score_is_applicable: overall_max_score !== null,
        overall_score: Math.round(100*(customMetric.metric_0 ?? 0)),
        overall_score_type: OVERALL_SCORE_TYPE.PERCENTAGE,
        overall_explanation: customMetric.text_0,
        metrics: metrics
    }
}

export const TeamIdToTeamView: {[k: string]: boolean} = {
    [ATTYX_TEAM_ID]: true,
    [TRELLUS_TEAM_ID]: true,
    [ELEVATEK12_TEAM_ID]: true,
    [VENACORP_TEAM_ID]: true,
    [GRAINCLEANER_TEAM_ID]: true,
    [TRENDHUNTER_TEAM_ID]: true,
    [GOODKIND_TEAM_ID]: true,
    [CAPITAL_GENERATIONS_TEAM_ID]: true,
    [PIGMENT_TEAM_ID]: true,
    [NINJAONE_TEAM_ID]: true,
    [PROPEL_TEAM_ID]: true,
    [GAINSIGHT_TEAM_ID]: true,
    [ATOB_TEAM_ID]: true,
    [MAGIC_TEAM_ID]: true,
    [MONEX_TEAM_ID]: true,
    [FLOATCARD_TEAM_ID]: true,
    [CLARION_TEAM_ID]: true,
    [SEESAW_TEAM_ID]: true,
}

export const TeamIdToMetricType: {[k: string]: (customMetric: CustomMetric) => MetricFormat} = {
    [ATTYX_TEAM_ID]: AttyxMetricFormat,
    [FOX_ORDERING_TEAM_ID]: FoxOrderingMetricFormat,
    [TRELLUS_TEAM_ID]: GenAIMetricFormat,
    [ELEVATEK12_TEAM_ID]: GenAIMetricFormat,
    [VENACORP_TEAM_ID]: GenAIMetricFormat,
    [GRAINCLEANER_TEAM_ID]: GenAIMetricFormat,
    [TRENDHUNTER_TEAM_ID]: GenAIMetricFormat,
    [GOODKIND_TEAM_ID]: GenAIMetricFormat,
    [CAPITAL_GENERATIONS_TEAM_ID]: GenAIMetricFormat,
    [PIGMENT_TEAM_ID]: GenAIMetricFormat,
    [NINJAONE_TEAM_ID]: GenAIMetricFormat,
    [PROPEL_TEAM_ID]: GenAIMetricFormat,
    [GAINSIGHT_TEAM_ID]: GenAIMetricFormat,
    [ATOB_TEAM_ID]: GenAIMetricFormat,
    [MAGIC_TEAM_ID]: GenAIMetricFormat,
    [MONEX_TEAM_ID]: GenAIMetricFormat,
    [FLOATCARD_TEAM_ID]: GenAIMetricFormat,
    [CLARION_TEAM_ID]: GenAIMetricFormat,
    [SEESAW_TEAM_ID]: GenAIMetricFormat,
}

export type MetricDiagnosticInfo = {diagnostic: Diagnostic, label: string, metric_name: string, metric_version: string, max_value: number}

export const GenericDiagnosticMetricInfo: MetricDiagnosticInfo = {diagnostic: Diagnostic.AVERAGE_SCORE, label: "Score", metric_name: "metric_0", metric_version: "generic_0", max_value: 1}

export const TeamIdToScoreMetricInfo: {[k: string]: {metric_name: string, metric_version: string}} = {
    [ATTYX_TEAM_ID]: {metric_name: "metric_0", metric_version: "attyx_1"},
    [TRELLUS_TEAM_ID]: GenericDiagnosticMetricInfo,
    [ELEVATEK12_TEAM_ID]: GenericDiagnosticMetricInfo,
    [VENACORP_TEAM_ID]: GenericDiagnosticMetricInfo,
    [GRAINCLEANER_TEAM_ID]: GenericDiagnosticMetricInfo,
    [TRENDHUNTER_TEAM_ID]: GenericDiagnosticMetricInfo,
    [GOODKIND_TEAM_ID]: GenericDiagnosticMetricInfo,
    [CAPITAL_GENERATIONS_TEAM_ID]: GenericDiagnosticMetricInfo,
    [PIGMENT_TEAM_ID]: GenericDiagnosticMetricInfo,
    [NINJAONE_TEAM_ID]: GenericDiagnosticMetricInfo,
    [PROPEL_TEAM_ID]: GenericDiagnosticMetricInfo,
    [GAINSIGHT_TEAM_ID]: GenericDiagnosticMetricInfo,
    [ATOB_TEAM_ID]: GenericDiagnosticMetricInfo,
    [MAGIC_TEAM_ID]: GenericDiagnosticMetricInfo,
    [MONEX_TEAM_ID]: GenericDiagnosticMetricInfo,
    [FLOATCARD_TEAM_ID]: GenericDiagnosticMetricInfo,
    [CLARION_TEAM_ID]: GenericDiagnosticMetricInfo,
    [SEESAW_TEAM_ID]: GenericDiagnosticMetricInfo,
}

export const HasCustomScore = (teamId: string) => TeamIdToScoreMetricInfo[teamId] !== undefined

export const DEFAULT_SUB_METRIC_INFO: MetricDiagnosticInfo[] = [
    {diagnostic: Diagnostic.SUB_CUSTOM_METRIC_ONE, label: "Opener", "metric_name": "metric_1", "metric_version": "generic_0", max_value: 2},
    {diagnostic: Diagnostic.SUB_CUSTOM_METRIC_TWO, label: "Objection", "metric_name": "metric_2", "metric_version": "generic_0", max_value: 2},
    {diagnostic: Diagnostic.SUB_CUSTOM_METRIC_THREE, label: "Qualification", "metric_name": "metric_3", "metric_version": "generic_0", max_value: 2},
    {diagnostic: Diagnostic.SUB_CUSTOM_METRIC_FOUR, label: "Closing", "metric_name": "metric_4", "metric_version": "generic_0", max_value: 2}
]

export const TeamIdToSubMetricInfo: {[k: string]: MetricDiagnosticInfo[]} = {
    [TRELLUS_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [ELEVATEK12_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [VENACORP_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [GRAINCLEANER_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [TRENDHUNTER_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [GOODKIND_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [CAPITAL_GENERATIONS_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [PIGMENT_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [NINJAONE_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [PROPEL_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [GAINSIGHT_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [ATOB_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [MAGIC_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [MONEX_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [FLOATCARD_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [CLARION_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
    [SEESAW_TEAM_ID]: DEFAULT_SUB_METRIC_INFO,
}

export const GET_ALL_SUB_METRIC_NAMES = Array.from(new Set(Object.values(TeamIdToSubMetricInfo).flat().map((v) => v.label)))


export const DEFAULT_ATTYX_METRIC_DEFINITIONS: CustomMetricDefinition[] = [
    {
        'metric_type': MetricType.QUALITATIVE,
        'metric_name': 'Service',
        'value': '',
        'options': ['Roofing', 'New HVAC', 'Both', 'Unknown'],
        'optionsToValueMap': {'Roofing': 1, 'New HVAC': 2, 'Both': 3, 'Unknown': 4},
        'field_name': 'metric_1',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.QUALITATIVE,
        metric_name: 'Type',
        value: 'metric_2',
        options: ['Inbound', 'Followup', 'Outbound'],
        optionsToValueMap: {'Inbound': 1, 'Followup': 2, 'Outbound': 3},
        field_name: 'metric_2',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.QUALITATIVE,
        metric_name: 'Stage',
        value: '',
        options: ['Beginning', 'Middle', 'End'],
        optionsToValueMap: {'Beginning': 0, 'Middle': 1, 'End': 2},
        field_name: 'metric_3',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Zip',
        value: '',
        field_name: 'metric_4',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Pain - Where/When',
        value: '',
        field_name: 'metric_5',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Pain - Reason',
        value: '',
        field_name: 'metric_6',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'IRA',
        value: '',
        field_name: 'metric_7',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Replacement',
        value: '',
        field_name: 'metric_8',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Current Bill',
        value: '',
        field_name: 'metric_9',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'No Cost',
        value: '',
        field_name: 'metric_10',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Credit',
        value: '',
        field_name: 'metric_11',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Homeowner',
        value: '',
        field_name: 'metric_12',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Employed',
        value: '',
        field_name: 'metric_13',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Address',
        value: '',
        field_name: 'metric_14',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Date',
        value: '',
        field_name: 'metric_15',
        'version': 'attyx_1'
    }, {
        metric_type: MetricType.SUCCESS,
        metric_name: 'Next Steps',
        value: '',
        field_name: 'metric_16',
        'version': 'attyx_1'
    }
]
export const DEFAULT_FOX_ORDERING_METRIC_DEFINITIONS: CustomMetricDefinition[] = [
    {
        metric_type: MetricType.QUALITATIVE,
        metric_name: 'Outcome',
        value: '',
        options: ['No Contact', 'VM Reached', 'Reached prospect - no proposal/apathy', 'Proposal received - no interested', 'Hot opportunity', 'Deal Won'],
        optionsToValueMap: {'No Contact': 0, 'VM Reached': 1, 'Reached prospect - no proposal/apathy': 2, 'Proposal received - no interested': 3, 'Hot opportunity': 4, 'Deal Won': 5},
        field_name: 'metric_0',
        version: 'fox_0'
    }
]

export const DEFAULT_METRIC_DEFINITIONS: CustomMetricDefinition[] = [
    {
        metric_type: MetricType.SCORE,
        metric_name: 'Opener',
        value: '',
        minRange: 0,
        maxRange: 2,
        field_name: 'metric_1',
        version: 'generic_0'
    }, {
        metric_type: MetricType.SCORE,
        metric_name: 'Objection',
        value: '',
        minRange: 0,
        maxRange: 2,
        field_name: 'metric_2',
        version: 'generic_0'
    }, {
        metric_type: MetricType.SCORE,
        metric_name: 'Qualification',
        value: '',
        minRange: 0,
        maxRange: 2,
        field_name: 'metric_3',
        version: 'generic_0'
    }, {
        metric_type: MetricType.SCORE,
        metric_name: 'Closing',
        value: '',
        minRange: 0,
        maxRange: 2,
        field_name: 'metric_4',
        version: 'generic_0'
    }
]

export const TeamIdToCustomMetricDefinitions: {[k: string]: CustomMetricDefinition[]} = {
    [ATTYX_TEAM_ID]: DEFAULT_ATTYX_METRIC_DEFINITIONS,
    [FOX_ORDERING_TEAM_ID]: DEFAULT_FOX_ORDERING_METRIC_DEFINITIONS,
    [TRELLUS_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [ELEVATEK12_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [VENACORP_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [GRAINCLEANER_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [TRENDHUNTER_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [GOODKIND_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [CAPITAL_GENERATIONS_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [PIGMENT_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [NINJAONE_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [PROPEL_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [GAINSIGHT_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [ATOB_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [MAGIC_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [MONEX_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [FLOATCARD_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
    [CLARION_TEAM_ID]: DEFAULT_METRIC_DEFINITIONS,
}